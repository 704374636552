import { useState } from "react";
import { toast } from "react-hot-toast";
import { LuDelete } from "react-icons/lu";
import { RiCloseLine } from "react-icons/ri";

import { Link, useNavigate } from "react-router-dom";
import api from "../../services/api";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import useStore from "../../services/store";

const Parcours = () => {
  const [step, setStep] = useState("id");
  const [values, setValues] = useState({
    region: "Alpes Provence",
    id: "",
    passcode: "",
  });

  return (
    <div className="w-full h-full flex flex-col items-center py-12 px-8">
      {
        {
          id: <IdStep setStep={setStep} values={values} setValues={setValues} />,
          passcode: <PasswordStep setStep={setStep} values={values} setValues={setValues} />,
        }[step || "id"]
      }
    </div>
  );
};

const IdStep = ({ setStep, values, setValues }) => {
  const { t } = useTranslation();
  const { setFakeUserId } = useStore();
  const [focused, setFocused] = useState(false);

  return (
    <div className="w-full flex flex-col gap-8 items-center">
      <div className="flex justify-center">
        <div className="flex items-center gap-3 shadow rounded-full px-4 py-3">
          <div>{values.region}</div>
          <button className="w-6 h-6 rounded-full bg-doxallia-background flex items-center justify-center" onClick={() => null}>
            <RiCloseLine className="w-4 h-4" />
          </button>
        </div>
      </div>
      <div className="w-full flex flex-start flex-col mt-4">
        <div className="text-2xl font-semibold">{t("id_step.mes_identifiants")}</div>
        <div className="text-sm">{t("id_step.saisissez_votre_identifiant_a_11_chiffres")}</div>
      </div>

      <div className="relative rounded-lg overflow-hidden items-center justify-center flex bg-slate-100 mt-4">
        <input
          value={values.id}
          className="w-[80%] border-none focus:outline-none bg-slate-100 text-center py-1 text-xl caret-primary"
          placeholder="00000000000"
          maxLength={11}
          onChange={(e) => {
            //check that the value is a number
            if (isNaN(e.target.value)) return;
            setValues({ ...values, id: e.target.value });
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        {focused && <div className="absolute w-[90%] h-0.5 bg-primary bottom-0 left-0 right-0 mx-auto" />}
      </div>

      <div className="text-xs text-primary">{t("id_step.ou_trouver_mon_identifiant")}</div>
      <button
        className="primary-btn"
        onClick={() => {
          setFakeUserId(values.id), setStep("passcode");
        }}
        disabled={values.id.length < 11}>
        {t("id_step.continuer")}
      </button>
    </div>
  );
};

const PasswordStep = ({ values, setStep, setValues }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { setFakeUser } = useStore();

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await api.post("/fake-users/signin", values);
      if (res.ok) {
        navigate("/doxallia/interface");
        setFakeUser(res.data);
      } else {
        setErrors({ ...errors, passcode: t("password_step.wrong_credentials") });
      }
    } catch (error) {
      console.error(error);
      toast.error(t("error.submitting"));
    }
    setLoading(false);
  };

  return (
    <div className="h-full flex flex-col items-center justify-between">
      <div className="space-y-6">
        <div className="flex justify-center">
          <div className="flex items-center gap-3 shadow rounded-full px-4 py-3">
            <div>{values.id}</div>
            <button
              className="w-6 h-6 rounded-full bg-doxallia-background flex items-center justify-center"
              onClick={() => {
                setStep("id");
                setValues({ ...values, id: "" });
              }}>
              <RiCloseLine className="w-4 h-4" />
            </button>
          </div>
        </div>
        <div className="w-full flex flex-start flex-col">
          <div className="text-2xl font-semibold">{t("password_step.code_personnel")}</div>
          <div className="text-sm">{t("password_step.saisissez_votre_code_a_6_chiffres")}</div>
        </div>

        <div className="w-full flex flex-row justify-center gap-4">
          <Digit value={values.passcode} index={0} onClick={() => setValues({ ...values, passcode: values.passcode.slice(0, 0) })} />
          <Digit value={values.passcode} index={1} onClick={() => setValues({ ...values, passcode: values.passcode.slice(0, 1) })} />
          <Digit value={values.passcode} index={2} onClick={() => setValues({ ...values, passcode: values.passcode.slice(0, 2) })} />
          <Digit value={values.passcode} index={3} onClick={() => setValues({ ...values, passcode: values.passcode.slice(0, 3) })} />
          <Digit value={values.passcode} index={4} onClick={() => setValues({ ...values, passcode: values.passcode.slice(0, 4) })} />
          <Digit value={values.passcode} index={5} onClick={() => setValues({ ...values, passcode: values.passcode.slice(0, 5) })} />
        </div>
        {errors.passcode && <div className="text-xs text-center text-red-500">{errors.passcode}</div>}
        <div className="w-full flex justify-center">
          <KeyBoard values={values} setValues={setValues} />
        </div>
      </div>
      <div className="w-full space-y-4">
        <button className="primary-btn" onClick={handleSubmit} disabled={values.passcode.length < 6}>
          {loading ? <Loader /> : t("password_step.valider")}
        </button>
        <div className="w-full flex justify-center">
          <Link to={`/doxallia/forgot-passcode?id=${values.id}`} className="text-xs text-center text-primary">
            {t("password_step.jai_oublie_mon_code_personnel")}
          </Link>
        </div>
      </div>
    </div>
  );
};

const Digit = ({ value, index, onClick }) => (
  <div className={`h-14 w-10 border border-doxallia-primary rounded flex items-center justify-center ${value.length === index ? "bg-doxallia-pastel" : ""}`} onClick={onClick}>
    {value[index] || ""}
  </div>
);

const KeyBoard = ({ setValues, values }) => {
  const handlePassChange = (value) => {
    if (values.passcode.length === 6) return;
    setValues({ ...values, passcode: values.passcode + value.toString() });
  };

  return (
    <div className="w-full grid grid-cols-3 justify-between">
      <button className="flex items-center justify-center h-20" onClick={() => handlePassChange(6)}>
        6
      </button>
      <button className="flex items-center justify-center h-20" onClick={() => handlePassChange(7)}>
        7
      </button>
      <button className="flex items-center justify-center h-20" onClick={() => handlePassChange(8)}>
        8
      </button>
      <button className="flex items-center justify-center h-20" onClick={() => handlePassChange(9)}>
        9
      </button>
      <button className="flex items-center justify-center h-20" onClick={() => handlePassChange(2)}>
        2
      </button>
      <button className="flex items-center justify-center h-20" onClick={() => handlePassChange(3)}>
        3
      </button>
      <button className="flex items-center justify-center h-20" onClick={() => handlePassChange(4)}>
        4
      </button>
      <button className="flex items-center justify-center h-20" onClick={() => handlePassChange(5)}>
        5
      </button>
      <button className="flex items-center justify-center h-20" onClick={() => handlePassChange(1)}>
        1
      </button>
      <div />
      <button className="flex items-center justify-center h-20" onClick={() => handlePassChange(0)}>
        0
      </button>
      <button className="flex items-center justify-center h-20" onClick={() => setValues({ ...values, passcode: values.passcode.slice(0, -1) })}>
        <LuDelete />
      </button>
    </div>
  );
};

export default Parcours;
