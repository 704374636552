import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CaCampaign from "../../assets/ca-campaign.png";
import toast from "react-hot-toast";

const Landing = () => {
  const { t } = useTranslation();

  return (
    <>
      <img src={CaCampaign} alt="CA Campaign" className="w-[350px]" />
      <div className="p-4 w-full flex items-center flex-col">
        <div className="w-1/2 mt-4 space-y-6">
          <Link className="ca-primary-btn" to="/ca/auth">
            {t("home_step.se_connecter")}
          </Link>
          <button className="ca-empty-btn" onClick={() => toast.error(t("home_step.cette_fonctionnalite_nest_pas_encore_disponible"))}>
            {t("home_step.devenir_client")}
          </button>
          <div className="text-center text-xs text-ca-primary">{t("home_step.decouvrir_lappli")}</div>
        </div>
      </div>
    </>
  );
};

export default Landing;
