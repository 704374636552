import React from "react";
import { Route, Routes } from "react-router-dom";

import Auth from "./Auth";
import Interface from "./Interface";
import Landing from "./Landing";
import ForgotPasscode from "./ForgotPasscode";
import ResetPasscode from "./ResetPasscode";

const CAParcours = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/auth" element={<Auth />} />
      <Route path="/interface" element={<Interface />} />
      <Route path="/forgot-passcode" element={<ForgotPasscode />} />
      <Route path="/reset-passcode" element={<ResetPasscode />} />
    </Routes>
  );
};

export default CAParcours;
