import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";

const ForgotPasscode = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const [values, setValues] = useState({
    region: "Alpes Provence",
    id: searchParams.get("id") || "",
    birthDay: "",
    birthMonth: "",
    birthYear: "",
  });
  const { t } = useTranslation();
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);

  const handleNumberChange = (value, key, digit) => {
    if (isNaN(value)) return;
    if (value.length > digit) return;
    setValues({ ...values, [key]: value });
  };

  const handleSubmit = () => {
    //check that birthdate is valid
    if (values.birthDay > 31) return toast.error(t("reset_password_step.jour_de_naissance_invalide"));

    if (values.birthMonth > 12) return toast.error(t("reset_password_step.mois_de_naissance_invalide"));

    if (values.birthYear > new Date().getFullYear()) return toast.error(t("reset_password_step.annee_de_naissance_invalide"));

    navigate("/choice?origin=doxallia");
  };

  useEffect(() => {
    if (!dayRef.current || !monthRef.current || !yearRef.current) return;
    if (values.birthDay.length === 2 && values.birthMonth.length < 2) return monthRef.current.focus();

    if (values.birthMonth.length === 2) return yearRef.current.focus();
  }, [values.birthDay, values.birthMonth, values.birthYear, dayRef, monthRef, yearRef]);

  return (
    <div className="px-8 py-12">
      <Link to="/doxallia/auth" className="h-5 mx-4 rounded-full bg-doxallia-background cursor-pointer w-5 flex items-center justify-center">
        <RiCloseLine className="w-4 h-4" />
      </Link>

      <div className="w-full mt-1 h-2 bg-doxallia-background relative">
        <div className="absolute w-[113px] h-2 bg-doxallia-primary" />
      </div>
      <div className="mx-4">
        <div className="text-2xl font-semibold mt-8">{t("reset_password_step.reinitialiser_mon_code_personnel")}</div>
        <div className="mt-8 text-sm text-slate-700 text-medium">1/3 Identification</div>

        <div className="w-full mt-4 border-b" />

        <div>
          <div className="w-full gap-2 flex flex-col">
            <div className="text-sm font-medium mt-4">{t("reset_password_step.identifiant")}</div>
            <div className="text-xs text-gray-500">{t("reset_password_step.saisissez_votre_identifiant_a_11_chiffres")}</div>
            <input placeholder="Ex: 98765432109" value={values.id} onChange={(e) => setValues({ ...values, id: e.target.value })} className="input" />
          </div>

          <div className="flex flex-row  items-center gap-4 my-8">
            <div className="flex items-center justify-center w-6 h-6 rounded-full bg-pastel text-primary">?</div>
            <div className="text-doxallia-primary underline text-sm">{t("reset_password_step.ou_trouver_mon_identifiant")}</div>
          </div>

          <div className="w-full gap-2 flex flex-col mb-12">
            <div className="text-sm font-medium">{t("reset_password_step.votre_date_de_naissance")}</div>
            <div className="w-full flex flex-row gap-2">
              <input
                ref={dayRef}
                placeholder={t("reset_password_step.birthday_day")}
                value={values.birthDay}
                onChange={(e) => handleNumberChange(e.target.value, "birthDay", 2)}
                className="input"
                autoComplete="bday-day"
                maxLength={2}
              />
              <input
                ref={monthRef}
                placeholder={t("reset_password_step.birthday_month")}
                value={values.birthMonth}
                onChange={(e) => handleNumberChange(e.target.value, "birthMonth", 2)}
                className="input"
                autoComplete="bday-month"
                maxLength={2}
              />
              <input
                ref={yearRef}
                placeholder={t("reset_password_step.birthday_year")}
                value={values.birthYear}
                onChange={(e) => handleNumberChange(e.target.value, "birthYear", 4)}
                className="input"
                autoComplete="bday-year"
                maxLength={4}
              />
            </div>
          </div>
        </div>

        <button
          className="primary-btn"
          onClick={handleSubmit}
          disabled={values.id.length < 11 || values.birthDay.length < 2 || values.birthMonth.length < 2 || values.birthYear.length < 4}>
          {t("reset_password_step.suivant")}
        </button>
      </div>
    </div>
  );
};
export default ForgotPasscode;
