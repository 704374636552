import { Link, Navigate } from "react-router-dom";

import TickIcon from "../../assets/tick.svg";
import RedCrossIcon from "../../assets/red-cross.svg";
import { useTranslation } from "react-i18next";
import useStore from "../../services/store";

const Fail = () => {
  const { user } = useStore();
  const { t } = useTranslation();

  if (user.unissey_status === "validated" && user.shareid_status === "validated") return <Navigate to="/unissey/success" />;

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="w-full items-start flex flex-col">
        <div className="text-xl font-semibold my-6">{t("fail_screen.nous_navons_pas_pu_valider_votre_identite")}</div>

        <div className="flex flex-col w-full gap-4 mt-8 mb-12">
          <div className="flex flex-row items-center justify-start gap-2 ">
            {user.unissey_content.details.face_comparison.result === "match" ? (
              <div className="h-8 w-8 bg-ca-pastel rounded-full flex items-center justify-center">
                <img src={TickIcon} alt="Recto" className="w-4 h-4" />
              </div>
            ) : user.unissey_content.details.face_comparison.result === "not_reached" ? (
              <div className="h-8 w-8 bg-orange-200 rounded-full flex items-center justify-center">
                <img src={RedCrossIcon} alt="Recto" className="w-4 h-4" />
              </div>
            ) : (
              <div className="h-8 w-8 bg-red-200 rounded-full flex items-center justify-center">
                <img src={RedCrossIcon} alt="Recto" className="w-4 h-4" />
              </div>
            )}

            <div>{t("fail_screen.analyse_de_la_biometrie")}</div>
          </div>

          <div className="flex flex-row items-center justify-start gap-2 ">
            {user.unissey_content.details.liveness.result === "genuine" ? (
              <div className="h-8 w-8 bg-ca-pastel rounded-full flex items-center justify-center">
                <img src={TickIcon} alt="Recto" className="w-4 h-4" />
              </div>
            ) : user.unissey_content.details.liveness.result === "not_reached" ? (
              <div className="h-8 w-8 bg-orange-200 rounded-full flex items-center justify-center">
                <img src={RedCrossIcon} alt="Recto" className="w-4 h-4" />
              </div>
            ) : (
              <div className="h-8 w-8 bg-red-200 rounded-full flex items-center justify-center">
                <img src={RedCrossIcon} alt="Recto" className="w-4 h-4" />
              </div>
            )}
            <div>{t("fail_screen.analyse_de_la_detection_du_vivant")}</div>
          </div>

          <div className="flex flex-row items-center justify-start gap-2 ">
            {user.shareid_status === "validated" ? (
              <div className="h-8 w-8 bg-ca-pastel rounded-full flex items-center justify-center">
                <img src={TickIcon} alt="Recto" className="w-4 h-4" />
              </div>
            ) : (
              <div className="h-8 w-8 bg-red-200 rounded-full flex items-center justify-center">
                <img src={RedCrossIcon} alt="Recto" className="w-4 h-4" />
              </div>
            )}

            <div>{t("fail_screen.authentication_of_identity_card")}</div>
          </div>
        </div>
      </div>

      <Link to="/choice" className="primary-btn mt-20">
        {t("fail_screen.reessayer")}
      </Link>
    </div>
  );
};

export default Fail;
