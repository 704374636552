import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, Outlet, useNavigate } from "react-router-dom";

import { Toaster } from "react-hot-toast";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import LoginPage from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Verify from "./pages/auth/Verify";
import Home from "./pages/home";
import Doxallia from "./pages/doxallia";
import CreditAgricole from "./pages/credit-agricole";
import ShareId from "./pages/share-id";

import "./services/i18n";
import Loader from "./components/Loader";
import Language from "./components/Language";
import api from "./services/api";
import useStore from "./services/store";

import LogoPrimary from "./assets/logo-primary.svg";
import Choice from "./pages/choice";
import Unissey from "./pages/unissey";

const App = () => {
  return (
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/verify" element={<Verify />} />
          </Route>

          <Route element={<ProtectedLayout />}>
            <Route element={<DoxalliaLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/choice" element={<Choice />} />
              <Route path="/unissey/*" element={<Unissey />} />
            </Route>
            <Route element={<BlankLayout />}>
              <Route path="/ca/*" element={<CreditAgricole />} />
              <Route path="/doxallia/*" element={<Doxallia />} />
              <Route path="/share-id/*" element={<ShareId />} />
            </Route>
          </Route>

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
      <Toaster />
    </I18nextProvider>
  );
};

const AuthLayout = () => {
  const { user } = useStore();

  if (user && user.email_verified) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="w-screen h-screen flex flex-col gap-8 items-center justify-start bg-doxallia-background p-8 overflow-y-scroll">
      <div className="w-full max-w-screen-sm items-start flex justify-between">
        <img src={LogoPrimary} className="logo" alt="Vite logo" />
        <Language />
      </div>
      <div className="flex-1 w-full max-w-screen-sm py-16">
        <Outlet />
      </div>
    </div>
  );
};

const ProtectedLayout = () => {
  const { user, setUser } = useStore();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fectData = async () => {
      setLoading(true);
      try {
        const res = await api.get("/user/refresh");
        if (res.ok) {
          setUser(res.data.user);
          api.setToken(res.data.token);
        } else {
          setUser(null);
          navigate("/login");
        }
      } catch (error) {
        setUser(null);
        navigate("/login");
      }
      setLoading(false);
    };
    fectData();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }
  if (!user || !user.email_verified) {
    return <Navigate to="/login" replace />;
  }

  if (!user.has_uploaded_id && !location.pathname.includes("share-id")) return <Navigate to="/share-id?route=onboarding" replace />;

  return <Outlet />;
};

const BlankLayout = () => {
  return (
    <div className="w-screen h-screen flex justify-center overflow-y-scroll">
      <div className="w-full h-full max-w-screen-sm flex flex-col items-center justify-start">
        <Outlet />
      </div>
    </div>
  );
};

const DoxalliaLayout = () => (
  <div className="w-screen h-screen flex flex-col gap-8 items-center justify-start bg-doxallia-background p-8 overflow-y-scroll">
    <div className="w-full max-w-screen-sm items-start flex justify-between">
      <img src={LogoPrimary} className="logo" alt="Vite logo" />
      <Language />
    </div>
    <div className="flex-1 w-full max-w-screen-sm">
      <Outlet />
    </div>
  </div>
);

export default App;
