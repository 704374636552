import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import IconParcoursA from "../../assets/parcours-a.png";
import IconParcoursB from "../../assets/parcours-b.png";

const Choice = () => {
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);

  return (
    <div className="space-y-6">
      <div className="text-3xl">
        <span className="font-semibold text-doxallia-primary">{t("ab_choice.selectionnez")}</span> {t("ab_choice.votre_parcours")}
      </div>

      <Link
        to={`/unissey?origin=${searchParams.get("origin") || "doxallia"}&route=a`}
        className="flex flex-row w-full border border-slate-500 gap-4 p-4 rounded-lg cursor-pointer items-center">
        <div className="min-w-[100px] h-[100px] bg-[#D3DDCF] flex items-center justify-center rounded-lg">
          <img src={IconParcoursA} alt="CA" />
        </div>
        <div className="flex flex-col  justify-between">
          <div className="text-black font-semibold">{t("ab_choice.parcours_a")}</div>
          <div>{t("ab_choice.utiliser_la_carte_didentite_enregistree_dans_la_base_de_donnees")}</div>
        </div>
      </Link>

      <Link
        to={`/share-id?origin=${searchParams.get("origin") || "doxallia"}&route=b`}
        className="flex flex-row w-full border border-slate-500 gap-4 p-4 rounded-lg cursor-pointer mt-6 items-center">
        <div className="min-w-[100px] h-[100px] bg-[#F1C9C7] flex items-center justify-center rounded-lg">
          <img src={IconParcoursB} alt="Doxallia" />
        </div>
        <div className="flex flex-col  justify-between">
          <div className="text-black font-semibold">{t("ab_choice.parcours_b")}</div>
          <div>{t("ab_choice.ne_pas_utiliser_la_carte_didentite_enregistree_dans_la_base_de_donnees")}</div>
        </div>
      </Link>
    </div>
  );
};

export default Choice;
