import { Route, Routes } from "react-router-dom";

import Sdk from "./Sdk";
import Success from "./Success";
import Fail from "./Fail";

const Unissey = () => {
  return (
    <Routes>
      <Route path="/" element={<Sdk />} />
      <Route path="/success" element={<Success />} />
      <Route path="/fail" element={<Fail />} />
    </Routes>
  );
};

export default Unissey;
