import { Route, Routes } from "react-router-dom";

import Sdk from "./Sdk";
import Waiting from "./Waiting";

const ShareId = () => {
  return (
    <Routes>
      <Route path="/" element={<Sdk />} />
      <Route path="/waiting" element={<Waiting />} />
    </Routes>
  );
};
export default ShareId;
