import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import DoxalliaBGSVG from "../../assets/doxallia-bg.svg";
import toast from "react-hot-toast";

const Landing = () => {
  const { t } = useTranslation();

  return (
    <>
      <img src={DoxalliaBGSVG} className="w-full max-w-sm" />
      <div className="mt-8 w-full p-4 flex flex-col gap-2">
        <Link className="primary-btn" to="/doxallia/auth">
          {t("home_step.se_connecter")}
        </Link>
        <button className="empty-btn" onClick={() => toast.error(t("home_step.cette_fonctionnalite_nest_pas_encore_disponible"))}>
          {t("home_step.devenir_client")}
        </button>
      </div>
    </>
  );
};

export default Landing;
