export const ENVIRONMENT = import.meta.env.MODE === "production" ? import.meta.env.ENVIRONMENT : "development";
export const API_URL = import.meta.env.MODE === "production" ? import.meta.env.VITE_API_URL : "http://localhost:4000";
export const APP_URL = import.meta.env.MODE === "production" ? import.meta.env.VITE_APP_URL : "http://localhost:3000";
export const SENTRY_DSN = import.meta.env.SENTRY_DSN || "https://34301bf2efd5f67dc7b345bebea43726@sentry.selego.co/149";

export const UNISSEY_API_URL = import.meta.env.UNISSEY_API_URL || "https://api-analyze.unissey.com/api/v3/analyze";

export const UNISSEY_API_KEY = import.meta.env.UNISSEY_API_KEY || "9be5f582-fba2-4319-a28e-a4f9f52ec434";

export const SHARE_ID_BUSINESS_SECRET =
  "OnYOcoYRaNl3q2jBykf9f6V3iaTOui4m8DW7nh1T5pZdzGndnL449JqwanQKCkZT.9hepAqoneUTXD1x9RIwLm3wLf0gfgMOF.eGp00dOjBqz6ADh8RrMO86DPw5dioWU0.5Cd6nn8L1xZax3Shw1zA9lrOqBXSsmd3hI2weDHTaAYNJOjujIq3p9LejKt7xgzL";
export const SHARE_ID_BUSINESS_IDENTIFIER = "39f982cc-c4ad-4dfc-880d-78d6e767b903";
