import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../services/api";
import LogoSecondary from "../../assets/logo-secondary.svg";
import { toast } from "react-hot-toast";
import DataSecurity from "../../assets/data-security.svg";

import { Trans, useTranslation } from "react-i18next";
import Loader from "../../components/Loader";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [needToAcceptConsent, setNeedToAcceptConsent] = useState(false);
  const { t } = useTranslation();
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await api.post("/user/signup", { email, hasGivenConsent: true });
      console.log(res, res.code === "EMAIL_ALREADY_EXISTS");
      if (res.ok) {
        setEmailSent(true);
        setNeedToAcceptConsent(false);
      } else if (res.code === "EMAIL_ALREADY_EXISTS") {
        setEmailSent(false);
        setNeedToAcceptConsent(false);
        setEmail("");
        toast.error(t("register_page.email_already_exists"));
      }
    } catch (error) {
      console.error(error);
      toast.error(t("error.submitting"));
    }
    setLoading(false);
  };

  const handleConsent = async () => {
    try {
      setNeedToAcceptConsent(true);
    } catch (error) {
      console.error(error);
    }
  };

  if (needToAcceptConsent) return <Consent handleSubmit={handleSubmit} />;

  if (emailSent)
    return (
      <div className="w-full space-y-8">
        <div className="text-3xl">
          <Trans i18nKey="register_page.email_sent" components={{ b: <span className="font-semibold text-doxallia-primary" /> }} />
        </div>
        <div className="mb-6 text-doxallia-primary underline self-start text-xl">{email}</div>
        <img src={DataSecurity} alt="Data security" className="mx-auto" />
      </div>
    );

  return (
    <div className="flex flex-col justify-between">
      <div className="w-full space-y-8">
        <img className="mx-auto" src={LogoSecondary} alt="Secondary logo" />
        <div className="text-3xl">
          <Trans t={t} i18nKey="register_page.welcome" components={{ b: <span className="text-doxallia-primary font-semibold" />, br: <br /> }} />
        </div>

        <Trans t={t} i18nKey="register_page.description" components={{ b: <span className="text-doxallia-primary font-semibold" /> }} />

        <div className="w-full space-y-2">
          <div className="text-sm font-medium">
            {t("register_page.adresse_mail")} <span className="text-red-600">*</span>
          </div>
          <input type="email" placeholder={t("register_page.entrez_votre_adresse_mail")} value={email} onChange={(e) => setEmail(e.target.value)} className="input" />
          <div className="text-doxallia-primary text-xs">{t("register_page.uniquement_les_mails_inscrits_par_l_and_apos_administrateur_sont_autorises")}</div>
        </div>

        <div className="w-full space-y-4">
          <button onClick={handleConsent} className="primary-btn" disabled={loading || !email}>
            {loading ? <Loader /> : t("register_page.sinscrire")}
          </button>
          <Link to="/login" className="empty-btn mt-3">
            {t("register_page.se_connecter")}
          </Link>
        </div>
      </div>
    </div>
  );
};

const Consent = ({ handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col gap-6">
        <div className="text-2xl">
          <Trans t={t} i18nKey="consent_page.title" components={{ b: <span className="text-doxallia-primary font-semibold" /> }} />
        </div>

        <div className="text-sm">
          <Trans t={t} i18nKey="consent_page.description.1" components={{ b: <span className="text-doxallia-primary font-semibold" /> }} />
        </div>
        <div className="text-sm">
          <Trans t={t} i18nKey="consent_page.description.2" components={{ b: <span className="text-doxallia-primary font-semibold" /> }} />
        </div>
        <div className="text-sm">
          <Trans t={t} i18nKey="consent_page.description.3" components={{ b: <span className="text-doxallia-primary font-bold underline" /> }} />
        </div>
        <div className="text-sm">
          <Trans t={t} i18nKey="consent_page.accept" components={{ b: <span className="text-doxallia-primary font-bold underline" /> }} />
        </div>
      </div>

      <button onClick={handleSubmit} className="primary-btn mt-8">
        {t("consent_page.accept_btn")}
      </button>
    </div>
  );
};

export default Register;
