import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShareID from "@shareid/onboarding_desktop";
import { useTranslation } from "react-i18next";

import useStore from "../../services/store";
import api from "../../services/api";
import toast from "react-hot-toast";

const ShareId = () => {
  const { t, i18n } = useTranslation();
  const { user } = useStore();
  const [token, setToken] = useState();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/share-id/token?origin=${searchParams.get("origin")}`);
        if (res.ok) setToken(res.data);
      } catch (error) {
        console.error(error);
        toast.error(t("error.fetching"));
      }
    };
    fetchData();
  }, []);

  const handleSuccess = async (data) => {
    try {
      const values = { shareid_request_id: data.uuid, shareid_content: { id: null }, shareid_status: "pending" };
      const res = await api.put("/user/", values);
      if (res.ok) navigate("waiting");
    } catch (error) {
      console.error(error);
      toast.error(t("error.submitting"));
    }
  };

  const handleFail = async () => {
    try {
      const values = { shareid_request_id: null, shareid_status: "failed" };
      const res = await api.put("/user/", values);
      if (res.ok) navigate("waiting");
    } catch (error) {
      console.error(error);
      toast.error(t("error.submitting"));
    }
  };

  if (!token) return null;

  return (
    <ShareID
      accessToken={token}
      metadata={{
        external_id: user._id,
        origin: searchParams.get("origin"),
        route: searchParams.get("route"),
      }}
      language={i18n.language}
      onFail={handleFail}
      onSuccess={handleSuccess}
      redirectUrl="https://app.doxallia-biometrie-dev.fr/share-id/waiting" // See if need to pass by API later
    />
  );
};

export default ShareId;
