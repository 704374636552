import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import useStore from "../../services/store";
import api from "../../services/api";
import Language from "../../components/Language";
import LogoPrimary from "../../assets/logo-primary.svg";

const Waiting = () => {
  const { t } = useTranslation();
  const { setUser } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get("/user/");
        if (res.data.shareid_status === "failed") navigate("/");
        if (res.ok && res.data.shareid_content?.id !== null) {
          await setUser(res.data);
          // if (res.data.shareid_route ==="b") navigate(`unissey?orgin=${res.data.shareid_content.metadata.origin || "doxallia"}`);
          if (res.data.shareid_content.metadata.route === "b") navigate(`/unissey?orgin=${res.data.shareid_content.metadata.origin || "doxallia"}&route=b`);
          else navigate("/");
        }
      } catch (error) {
        console.error(error);
        toast.error(t("error.fetching"));
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col items-center text-doxallia-primary p-8">
      <div className="sticky top-0 w-full items-start flex justify-between">
        <img src={LogoPrimary} className="logo" alt="Vite logo" />
        <Language />
      </div>
      <div className="h-full flex justify-center flex-col items-center">
        <div className="loader bg-primary mb-20"></div>
        <h1 className="text-2xl font-extrabold mb-28">{t("without_id_waiting.waiting_for_result")}</h1>
        <div className="text-md text-center mb-2">{t("without_id_waiting.please_follow_workflow")}</div>
      </div>
    </div>
  );
};

export default Waiting;
