import Loader from './Loader';

export default function Button({
  text,
  onPress,
  loading = false,
  variant = 'doxallia-primary',
  className = '',
  disabled = false,
}) {
  const getBackgroundColor = () => {
    if (disabled) return 'bg-gray-300';
    switch (variant) {
      case 'doxallia-primary':
        return 'bg-doxallia-primary';
      case 'doxallia-secondary':
        return 'bg-transparent border border-doxallia-primary';
      case 'ca-primary':
        return 'bg-ca-primary';
      case 'ca-secondary':
        return 'bg-transparent border border-ca-primary';
    }
  };

  const getTextColor = () => {
    if (disabled) return 'text-gray-500';
    switch (variant) {
      case 'doxallia-primary':
        return 'text-white';
      case 'doxallia-secondary':
        return 'text-doxallia-primary';
      case 'ca-primary':
        return 'text-white';
      case 'ca-secondary':
        return 'text-ca-primary';
    }
  };

  return (
    <div
      onClick={() => {
        if (!loading && !disabled) onPress();
      }}
      disabled={disabled}
      className={`w-full cursor-pointer rounded-3xl border items-center px-2 justify-center flex  ${getBackgroundColor()} h-[48px] ${className} `}
    >
      {loading ? (
        <Loader className={getTextColor()} />
      ) : (
        <div className={`${getTextColor()} font-medium `}>{text}</div>
      )}
    </div>
  );
}
