import { Link, Navigate } from "react-router-dom";

import CircleCheck from "../../assets/circle-check.svg";
import TickIcon from "../../assets/tick.svg";
import { useTranslation } from "react-i18next";
import useStore from "../../services/store";

const Success = () => {
  const { user } = useStore();
  const { t } = useTranslation();
  if (user.unissey_status !== "validated" || user.shareid_status !== "validated") return <Navigate to="/unissey/fail" />;

  return (
    <div className="h-full flex justify-between flex-col">
      <div className="w-full items-start flex flex-col">
        <div className="text-xl font-semibold my-6">{t("success_screen.votre_identite_a_bien_ete_confirmee")}</div>

        <div className="flex flex-col w-full gap-4 mt-8 mb-12">
          <div className="flex flex-row items-center justify-start gap-2 ">
            <div className="h-8 w-8 bg-ca-pastel rounded-full flex items-center justify-center">
              <img src={TickIcon} alt="Recto" className="w-4 h-4" />
            </div>
            <div>{t("success_screen.analyse_de_la_biometrie")}</div>
          </div>

          <div className="flex flex-row items-center justify-start gap-2 ">
            <div className="h-8 w-8 bg-ca-pastel rounded-full flex items-center justify-center">
              <img src={TickIcon} alt="Recto" className="w-4 h-4" />
            </div>
            <div>{t("success_screen.analyse_de_la_detection_du_vivant")}</div>
          </div>

          <div className="flex flex-row items-center justify-start gap-2 ">
            <div className="h-8 w-8 bg-ca-pastel rounded-full flex items-center justify-center">
              <img src={TickIcon} alt="Recto" className="w-4 h-4" />
            </div>
            <div>{t("success_screen.authentication_of_identity_card")}</div>
          </div>
        </div>

        <div className="w-full flex items-center justify-center ">
          <img src={CircleCheck} alt="Check" />
        </div>
      </div>

      <div className="w-full space-y-4">
        <Link to={`/${user.unissey_origin || "doxallia"}/reset-passcode`} className="primary-btn">
          {t("success_screen.modifier_mon_mot_de_passe")}
        </Link>
        <Link to="/" className="empty-btn">
          {t("success_screen.annuler")}
        </Link>
      </div>
    </div>
  );
};

export default Success;
