import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import useStore from "../../services/store";
import Loader from "../../components/Loader";

const Verify = () => {
  const { setUser } = useStore();
  const [values, setValues] = useState({ password: "", confirmPassword: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const handleSubmit = async () => {
    if (loading) return;
    if (!values.password) return toast.error(t("verify.veuillez_remplir_tous_les_champs"));
    if (values.password.length < 8) return toast.error(t("verify.le_mot_de_passe_doit_contenir_au_moins_8_caracteres"));
    if (values.password !== values.confirmPassword) return toast.error(t("verify.les_mots_de_passe_ne_correspondent_pas"));
    setLoading(true);

    try {
      const res = await api.post("/user/verify", { password: values.password, token });
      if (res.ok) {
        api.setToken(res.token);
        setUser(res.data);
        navigate("/share-id?route=onboarding");
      }
    } catch (error) {
      console.error(error);
      toast.error(t("error.submitting"));
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col justify-between">
      <div className="w-full space-y-8">
        <div className="text-3xl text-doxallia-primary">
          {t("verify.choisissez")} <b>{t("verify.votre_mot_de_passe_securise")}</b>
        </div>

        <Trans t={t} i18nKey="verify.inscrivez_vous" components={{ b: <span className="text-doxallia-primary font-semibold" /> }} />

        <div className="w-full space-y-2">
          <div className="text-sm font-medium">
            {t("verify.mot_de_passe")} <span className="text-red-600">*</span>
          </div>
          <input
            placeholder={t("verify.entrez_un_nouveau_mot_de_passe")}
            value={values.password}
            onChange={(e) => setValues({ ...values, password: e.target.value })}
            className="input"
            type="password"
          />
        </div>

        <div className="w-full space-y-2">
          <div className="text-sm font-medium">
            {t("verify.confirmez_votre_mot_de_passe")}
            <span className="text-red-600">*</span>
          </div>
          <input
            placeholder={t("verify.entrez_un_nouveau_mot_de_passe")}
            value={values.confirmPassword}
            onChange={(e) => setValues({ ...values, confirmPassword: e.target.value })}
            className="input"
            type="password"
          />
        </div>
      </div>

      <button onClick={handleSubmit} className="primary-btn mt-8" disabled={loading || !values.password}>
        {loading ? <Loader /> : t("verify.continuer")}
      </button>
    </div>
  );
};

export default Verify;
