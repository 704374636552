import { useTranslation } from 'react-i18next';

const Language = () => {
  const { i18n } = useTranslation();
  const handleChangeLanguage = (lang) => {
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
  };
  return (
    <div className=" gap-2 flex">
      <button
        className=" bg-slate-200 rounded-full px-4 shadow-sm hover:shadow-none hover:bg-slate-100 transition-colors group"
        onClick={() => handleChangeLanguage('en')}
      >
        <p className="group-hover:scale-110 transition-transform ease-in-out text-xl">🇬🇧</p>
      </button>
      <button
        className=" bg-slate-200 rounded-full px-4 shadow-sm hover:shadow-none hover:bg-slate-100 transition-colors group"
        onClick={() => handleChangeLanguage('fr')}
      >
        <p className="group-hover:scale-110 transition-transform ease-in-out text-xl">🇫🇷</p>
      </button>
    </div>
  );
};

export default Language;
