export const ErrorMessages = {
  SERVER_ERROR: 'Une erreur est survenue',
  EMAIL_OR_PASSWORD_INVALID: 'Email ou mot de passe invalide',
  UNAUTHORIZED_DOMAIN: 'Email non autorisé',
  DEFAULT: 'Une erreur est survenue',
  INVALID_TOKEN: "Le lien est invalide, contactez l'administrateur",
  EXPIRED_TOKEN: "Le lien a expiré, contactez l'administrateur",
  EMAIL_NOT_VERIFIED: 'Email non vérifié',
  USER_NOT_FOUND: 'Identifiant ou code personnel incorrect',
  EMAIL_ALREADY_EXISTS: 'Email déjà utilisé',
};
