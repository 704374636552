import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";

import api from "../../services/api";
import useStore from "../../services/store";
import IconCA from "../../assets/ca.svg";
import IconDoxallia from "../../assets/doxallia.svg";

const Home = () => {
  const { t } = useTranslation();
  const { setUser } = useStore();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogOut = async () => {
    if (loading) return;
    setLoading(true);
    try {
      api.removeToken();
      setUser(null);
      navigate("/login");
    } catch (error) {
      console.error(error);
      toast.error(t("error.submitting"));
    }
    setLoading(false);
  };

  return (
    <div className="h-full w-full space-y-6">
      <div className="text-3xl">
        <Trans t={t} i18nKey="home_page.welcome" components={{ b: <span className="text-doxallia-primary font-semibold" /> }} />
      </div>

      <div className="space-y-4">
        <Link to="/ca" className="flex flex-row w-full border border-slate-500 gap-4 p-4 rounded-lg cursor-pointer">
          <div className="min-w-[100px] h-[100px] bg-ca-pastel flex items-center justify-center rounded-lg">
            <img src={IconCA} alt="CA" />
          </div>
          <div className="flex flex-col  justify-between">
            <div className="text-ca-primary font-semibold">Crédit Agricole</div>
            <div>{t("home_page.utiliser_linterface_credit_agricole_lors_du_parcours_de_biometrie")}</div>
          </div>
        </Link>

        <Link to="/doxallia" className="flex flex-row w-full border border-slate-500 gap-4 p-4 rounded-lg cursor-pointer">
          <div className="min-w-[100px] h-[100px] bg-doxallia-pastel flex items-center justify-center rounded-lg">
            <img src={IconDoxallia} alt="Doxallia" />
          </div>
          <div className="flex flex-col  justify-between">
            <div className="text-doxallia-primary font-semibold">Doxallia</div>
            <div>{t("home_page.utiliser_linterface_doxallia_lors_du_parcours_de_biometrie")}</div>
          </div>
        </Link>
      </div>

      <div className="mt-8 underline cursor-pointer text-doxallia-primary text-sm" onClick={handleLogOut}>
        {t("home_page.se_deconnecter")}
      </div>
    </div>
  );
};

export default Home;
