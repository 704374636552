import { useState } from "react";

import LogoPrimary from "../../assets/logo-primary.svg";
import Button from "../../components/Button";
import { toast } from "react-hot-toast";
import { ErrorMessages } from "../../utils/errorMessages";

import { useNavigate } from "react-router-dom";
import API from "../../services/api";
import CircleCheck from "../../assets/circle-check.svg";
import { useTranslation } from "react-i18next";
import Language from "../../components/Language";
import useStore from "../../services/store";
import { LuDelete } from "react-icons/lu";
import { MdOutlineArrowBack } from "react-icons/md";

const ResetPasscode = () => {
  const { t } = useTranslation();
  const { fakeUserId } = useStore();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState("password");
  const [loading, setLoading] = useState(false);
  const [passcodeReseted, setPasscodeReseted] = useState(false);

  const handleSubmit = async () => {
    if (loading) return;
    try {
      if (!password) return toast.error(t("reset_passcode.veuillez_remplir_tous_les_champs"));
      if (password.length !== 6) return toast.error(t("reset_passcode.le_mot_de_passe_doit_contenir_6_chiffres"));
      if (password !== confirmPassword) return toast.error(t("reset_passcode.les_mots_de_passe_ne_correspondent_pas"));
      setLoading(true);
      console.log(fakeUserId);
      const { ok, code } = await API.post("/fake-users/reset-passcode", {
        id: fakeUserId,
        passcode: password,
      });
      if (!ok) throw new Error(code);
      setPasscodeReseted(true);
    } catch (error) {
      toast.error(ErrorMessages[error?.code] ?? ErrorMessages.DEFAULT);
    } finally {
      setLoading(false);
    }
  };

  if (passcodeReseted) return <SuccessPage />;
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-start bg-doxallia-background px-8 py-12">
      <div className="w-full h-full flex flex-col justify-between">
        <div>
          <div className="w-full items-start flex justify-between">
            <img src={LogoPrimary} className="logo" alt="Vite logo" />
            <Language />
          </div>

          {step === "password" && (
            <>
              <div className="text-2xl my-20 text-center">
                {t("reset_passcode.definissez_votre_nouveau")} <span className="font-semibold ">{t("reset_passcode.mot_de_passe")}</span>
              </div>
              <PasswordComponent value={password} onChange={setPassword} />
            </>
          )}

          {step === "confirm" && (
            <>
              <button
                className="my-8 underline cursor-pointer flex items-center text-doxallia-primary"
                onClick={() => {
                  setStep("password");
                  setConfirmPassword("");
                  setPassword("");
                }}>
                <MdOutlineArrowBack className="mr-1 text-doxallia-primary" />
                {t("reset_passcode.retour")}
              </button>
              <div className="text-2xl mb-8 text-center">
                {t("reset_passcode.confirmez_votre_nouveau")} <span className="font-semibold ">{t("reset_passcode.mot_de_passe")}</span>
              </div>
              <PasswordComponent value={confirmPassword} onChange={setConfirmPassword} />
            </>
          )}
        </div>

        <Button
          text={step === "password" ? t("reset_passcode.continuer") : t("reset_passcode.confirmer_la_modification")}
          className="mt-16"
          loading={loading}
          disabled={(step === "password" && password.length !== 6) || (step === "confirm" && confirmPassword.length !== 6)}
          onPress={step === "password" ? () => setStep("confirm") : handleSubmit}
        />
      </div>
    </div>
  );
};

export default ResetPasscode;

const PasswordComponent = ({ value, onChange }) => {
  const handlePassChange = (val) => {
    if (val === "-") {
      onChange(value.slice(0, -1));
    } else {
      if (value.length === 6) return;
      onChange(value + val);
    }
  };

  const renderPassword = () => {
    if (!value) return <div className="h-6" />;

    const passwordArray = [];
    for (let i = 0; i < value.length; i++) {
      passwordArray.push(<span key={i}>*</span>);
    }
    return passwordArray;
  };

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="w-full flex flex-row items-center justify-center gap-4">{renderPassword()}</div>
      <KeyBoard handlePassChange={handlePassChange} />
    </div>
  );
};

const SuccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-between bg-doxallia-background px-8 py-12">
      <div className="flex flex-col items-center">
        <div className="w-full items-start flex justify-between">
          <img src={LogoPrimary} className="logo" alt="Vite logo" />
          <Language />
        </div>
        <div>
          <div className="text-2xl my-4">
            {t("success_page.votre_mot_de_passe")}
            <span className="font-semibold text-doxallia-primary "> {t("success_page.a_bien_ete_modifie")}</span>
          </div>
          <div>
            {t("success_page.vous_pouvez_vous_connecter_avec")}
            <span className="text-doxallia-primary font-semibold">{t("success_page.votre_nouveau_mot_de_passe")}</span>
          </div>
        </div>

        <img src={CircleCheck} alt="Check" className="my-12" />
      </div>
      <Button text={t("success_page.se_connecter")} className="mt-20" onPress={() => navigate("../auth")} />
    </div>
  );
};
const KeyBoard = ({ handlePassChange }) => {
  return (
    <div className="w-[100%] flex flex-col gap-5 mt-4">
      <div className="flex flex-row w-full">
        <div className="w-1/3 text-center py-3 cursor-pointer" onClick={() => handlePassChange(6)}>
          6
        </div>
        <div className="w-1/3 text-center py-3 cursor-pointer" onClick={() => handlePassChange(7)}>
          7
        </div>
        <div className="w-1/3 text-center py-3 cursor-pointer" onClick={() => handlePassChange(0)}>
          0
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-1/3 text-center py-3 cursor-pointer" onClick={() => handlePassChange(9)}>
          9
        </div>
        <div className="w-1/3 text-center py-3 cursor-pointer" onClick={() => handlePassChange(2)}>
          2
        </div>
        <div className="w-1/3 text-center py-3 cursor-pointer" onClick={() => handlePassChange(8)}>
          8
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-1/3 text-center py-3 cursor-pointer" onClick={() => handlePassChange(1)}>
          1
        </div>
        <div className="w-1/3 text-center py-3 cursor-pointer" onClick={() => handlePassChange(3)}>
          3
        </div>
        <div className="w-1/3 text-center py-3 cursor-pointer" onClick={() => handlePassChange(4)}>
          4
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-1/3" />
        <div className="w-1/3 text-center py-3 cursor-pointer" onClick={() => handlePassChange(5)}>
          5
        </div>
        <button className="w-1/3 flex items-center justify-center" onClick={() => handlePassChange("-")}>
          <LuDelete />
        </button>
      </div>
    </div>
  );
};
