import GearIcon from "../../assets/gear.svg";
import TabletIcon from "../../assets/tablet.svg";
import CardIcon from "../../assets/card.svg";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import EyeHideIcon from "../../assets/eye-hide.svg";
import RightArrowIcon from "../../assets/right-arrow.svg";
import { useTranslation } from "react-i18next";

const Interface = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="px-8 py-12">
      <div className="flex flex-row items-center justify-between w-full">
        <div className="font-semibold text-xl my-8">{t("fake_user_interface.bonjour_demo")}</div>
        <div className="w-8 h-8 bg-slate-100 flex items-center justify-center rounded-full">
          <img src={GearIcon} alt="gear" className="w-6 h-6 text-ca-primary" />
        </div>
      </div>
      <div className="bg-white w-full py-2 rounded-lg">
        <div className="w-full border-b px-4 pb-4 mb-4">
          <div className="flex flex-row justify-between">
            <div className="font-medium">{t("fake_user_interface.compte_de_depot")}</div>
            <div className="text-ca-primary font-semibold text-lg">830,39€</div>
          </div>
          <div className="text-slate-500 text-xs font-medium">322XXXX871</div>
        </div>
        <div className="px-4 flex flex-col gap-4">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-2">
              <div className="h-8 w-8 flex items-center justify-center rounded-full bg-gray-800">
                <img src={CardIcon} alt="card" className="w-5 h-5" />
              </div>
              <div className="text-slate-800 truncate">PARKING GRAND HOTEL</div>
            </div>

            <div className="text-sm font-semibold">- 45,58€</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-2">
              <div className="h-8 w-8 flex items-center justify-center rounded-full  bg-pink-700">
                <img src={RightArrowIcon} alt="card" className="w-5 h-5" />
              </div>
              <div className="text-slate-800 truncate">WEB LOYER APPT NIMES</div>
            </div>

            <div className="px-1 bg-ca-pastel text-ca-primary text-sm font-semibold">800,00 €</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-2">
              <div className="h-8 w-8 flex items-center justify-center rounded-full bg-white border border-gray-500">
                <img src={EyeHideIcon} alt="card" className="w-5 h-5" />
              </div>
              <div className="text-slate-800 truncate">PARIS PREFECTURE 01/05</div>
            </div>

            <div className="text-sm font-semibold">- 20,00€</div>
          </div>
        </div>
      </div>
      <div className="bg-white w-full rounded-lg px-4 py-2 flex flex-row justify-between items-center my-4">
        <div className="flex flex-row items-center gap-2">
          <div className="w-7 h-7 bg-teal-500 rounded-full flex items-center justify-center">
            <img src={TabletIcon} alt="tablet" className="w-4 h-4 text-ca-primary" />
          </div>

          <div className="font-medium text-sm">{t("fake_user_interface.mes_demarches")}</div>
        </div>

        <div className="bg-red-500 w-5 h-5 flex items-center justify-center rounded-full">
          <div className="text-white font-medium">3</div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-full items-start text-slate-500 uppercase text-xs font-semibold my-4">{t("fake_user_interface.mes_raccourcis")}</div>
        <div className="flex flex-row w-full gap-4">
          <div className="w-1/2 bg-white p-4 rounded-lg">
            <div className="h-8 w-8 flex items-center justify-center bg-ca-primary rounded-full">
              <img src={CardIcon} alt="card" className="w-5 h-5" />
            </div>

            <div className="font-medium pr-12">{t("fake_user_interface.partager_mon_rib")}</div>
          </div>
          <div className="w-1/2 bg-white p-4 rounded-lg">
            <div className="h-8 w-8 flex items-center justify-center bg-teal-500 rounded-full">
              <img src={CardIcon} alt="card" className="w-5 h-5" />
            </div>
            <div className="font-medium pr-12">{t("fake_user_interface.gerer_mes_cartes")}</div>
          </div>
        </div>
      </div>

      <Button text={t("fake_user_interface.demarrer_un_nouveau_parcours")} onPress={() => navigate("/")} className=" mt-12" />
    </div>
  );
};

export default Interface;
