//import style from './Layout.module.scss'
import { useState } from "react";
import { FR, EN, OverlayDisplayMode, SelfieCapture } from "@unissey/sdk-react";
import { UNISSEY_API_KEY, UNISSEY_API_URL } from "../../services/config";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";

import { toast } from "react-hot-toast";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import useStore from "../../services/store";

const Unissey = () => {
  const { t, i18n } = useTranslation();
  const { user, setUser } = useStore();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);

  const handleSelfieCaptureData = async (e) => {
    if (loading) return;
    setLoading(true);
    const xhttp = new XMLHttpRequest();
    const form = new FormData();
    const data = e.detail;

    const byteCharacters = atob(user.shareid_content.document_front);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    form.append("reference", blob);

    form.append("selfie", data.media);
    form.append("processings", "liveness,face-comparison");
    form.append("gdpr-consent", "true");

    xhttp.onreadystatechange = async function () {
      if (this.readyState === 4 && this.status === 200) {
        try {
          const json = JSON.parse(this.responseText);
          const res = await api.post("/unissey", { ...json.data, origin: searchParams.get("origin") || "doxallia", route: searchParams.get("route") || "a" });
          if (res.ok) {
            setUser(res.data);
            if (res.unissey_status === "validated") navigate("success");
            else navigate("fail");
          }
        } catch (error) {
          console.error(error);
          toast.error(t("full_capture_check.une_erreur_est_survenue_veuillez_reessayer_plus_tard"));
        }
      } else if (this.readyState === 4 && this.status !== 200) {
        toast.error(t("full_capture_check.une_erreur_est_survenue_veuillez_reessayer_plus_tard"));
        setLoading(false);
      }
      if (this.readyState === 4) setLoading(false);
    };
    xhttp.open("POST", UNISSEY_API_URL, true);
    xhttp.setRequestHeader("Accept", "multipart/form-data");
    xhttp.setRequestHeader("Authorization", UNISSEY_API_KEY);
    xhttp.send(form);
  };

  if (loading) {
    return (
      <div className="w-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <SelfieCapture
      strings={i18n.language === "en" ? EN.selfieCapture : FR.selfieCapture}
      onSelfie={handleSelfieCaptureData}
      config={{
        // How to display the overlay. All fields are optional. These are the default values.
        overlayConfig: {
          // Shape of the overlay. Possible values are: OVAL, RECT_PORTRAIT, RECT_LANDSCAPE, ID_DOCUMENT.
          displayMode: OverlayDisplayMode.RECT_PORTRAIT,
          // Colors (RGBA) used to display the overlay.
          colors: {
            background: [255, 255, 255, 0.8],
            innerBorder: [255, 255, 255, 1],
            progressColor: [0, 188, 212, 1],
          },
        },
      }}
    />
  );
};

export default Unissey;
