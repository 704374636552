import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";

import Loader from "../../components/Loader";
import api from "../../services/api";
import useStore from "../../services/store";
import { ENVIRONMENT } from "../../services/config";

const Login = () => {
  const { setUser } = useStore();
  const [values, setValues] = useState({
    email: ENVIRONMENT === "development" ? "hugo.charon+2222@selego.co" : "",
    password: ENVIRONMENT === "development" ? "azertyuiop" : "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await api.post("/user/signin", { email: values.email, password: values.password });
      if (res.ok) {
        setUser(res.data);
        api.setToken(res.token);
        navigate("/");
      }
    } catch (error) {
      console.error(error);
      toast.error(t("error.submitting"));
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col justify-between">
      <div className="w-full space-y-8">
        <div className="text-3xl">
          <Trans t={t} i18nKey="login_page.welcome" components={{ b: <span className="text-doxallia-primary font-semibold" />, br: <br /> }} />
        </div>

        <div className="w-full space-y-2">
          <div className="text-sm font-medium">
            {t("login_page.adresse_mail")} <span className="text-red-600">*</span>
          </div>

          <input
            type="email"
            placeholder={t("login_page.entrez_votre_adresse_mail")}
            value={values.email}
            onChange={(e) => setValues({ ...values, email: e.target.value })}
            className="input"
          />
        </div>

        <div className="w-full space-y-2">
          <div className="text-sm font-medium">
            {t("login_page.mot_de_passe")} <span className="text-red-600">*</span>
          </div>
          <input
            placeholder={t("login_page.entrez_votre_mot_de_passe")}
            value={values.password}
            onChange={(e) => setValues({ ...values, password: e.target.value })}
            type="password"
            className="input"
          />
        </div>
      </div>
      <div className="w-full mt-8">
        <button onClick={handleSubmit} className="primary-btn" disabled={loading || !values.email || !values.password}>
          {loading ? <Loader szie="small" /> : t("login_page.se_connecter")}
        </button>
        <Link to="/register" className="empty-btn mt-3">
          {t("login_page.btn_sinscrire")}
        </Link>
      </div>
    </div>
  );
};

export default Login;
