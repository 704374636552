import React from 'react';
import ReactDOM from 'react-dom/client';
// import * as Sentry from "@sentry/react";

import App from './App.jsx';
import './index.css';
// import { ENVIRONMENT, SENTRY_DSN } from "./config";

// if (ENVIRONMENT !== "development") {
//   Sentry.init({
//     dsn: SENTRY_DSN,
//     integrations: [Sentry.replayIntegration()],
//     environment: "app",
//     tracesSampleRate: 0.1,
//   });
// }

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
