import { create } from "zustand";

const useStore = create((set) => ({
  user: null,
  setUser: (user) => set(() => ({ user })),
  fakeUser: null,
  setFakeUser: (fakeUser) => set(() => ({ fakeUser })),
  fakeUserId: null,
  setFakeUserId: (fakeUserId) => set(() => ({ fakeUserId })),
}));

export default useStore;
